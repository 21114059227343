<template>
  <v-container
    fluid
    tag="section"
  >
    <v-card flat>
      <EventTable
        :events="events"
        :open="false"
        team
        @row="open"
        @input="(id, ev) => input(id, ev)"
        @view="(id, ev) => view(id, ev)"
      />
    </v-card>
    <ul>
      <li>Um die Infos einer Begegnung zu bearbeiten, klicken Sie auf eine Zeile</li>
    </ul>
    <edit-event
      v-model="editdialog.open"
      :id="editdialog.id"
    />
  </v-container>
</template>

<script>
import gql from 'graphql-tag'
import { deleteQuery, useGraphQL } from '@/plugins/graphql'

const query = `
  _id
  identifiers { name value }
  type
  ... on StbM2021League {
    name
    shortName
    order
    teams {
      team { _id name }
      order
    }
    events {
      _id
      name
      startDate
      teams {
        team { _id name }
        order
        home
        neutralwaiver
        result { final score gp tp }
      }
      venue {
        _id
        name
        address { streetAddress streetNumber postalCode addressLocality }
      }
      internalComment
      matchday {
        _id
        name
        startDate
      }
    }
  }
`

export default {
  name: 'events',

  setup (props, context) {
    return {
      ...useGraphQL(context)
    }
  },

  components: {
    EditEvent: () => import('../dialogs/EditEvent'),
    EventTable: () => import('../../components/EventTable')
  },

  props: {
    id: {
      type: String,
      required: true
    },
    team: {
      type: String,
      required: true
    }
  },

  data: () => ({
    EventFind: [],
    editdialog: {
      open: false,
      id: null
    }
  }),

  computed: {
    events () {
      return this.EventFind.reduce((acc, curr) => {
        acc.push(...(curr.events || []).filter(e => !!e.teams?.find(t => t.team._id === this.team)).map(e => ({
          ...e,
          liga: curr,
          mannschaften: e.teams?.slice().sort((a, b) => {
            if (a.home !== b.home) return a.home ? -1 : 1
            return a.order < b.order ? -1 : 1
          }).map(t => t.team.name) || [],
          nv: e.teams?.slice().sort((a, b) => {
            if (a.home !== b.home) return a.home ? -1 : 1
            return a.order < b.order ? -1 : 1
          }).map(t => t.neutralwaiver) || [],
          ergebnisse: e.teams?.slice().sort((a, b) => {
            if (a.home !== b.home) return a.home ? -1 : 1
            return a.order < b.order ? -1 : 1
          }).map(t => t.result) || []
        })))
        return acc
      }, []).sort((a, b) => {
        if (a.startDate !== b.startDate) return a.startDate < b.startDate ? -1 : 1
        if (a.liga.order !== b.liga.order) return a.liga.order < b.liga.order ? -1 : 1
      })
    }
  },

  methods: {
    input (id, ev) {
      this.$openlink(`/input/${id}`, ev)
    },
    view (id, ev) {
      this.$openlink(`/view/${id}`, ev)
    },
    open (item) {
      this.editdialog.id = item._id
      this.editdialog.open = true
    }
  },

  apollo: {
    EventFind: {
      query: gql`
          query($parent: UUID) { EventFind(parent: $parent) { ${query} }}
        `,
      variables () {
        return {
          parent: this.id
        }
      }
    },
    $subscribe: {
      EventUpdate: {
        query: gql`
          subscription($parent: UUID) { EventUpdate(parent: $parent) { ${query} }}
        `
      },
      EventDelete: {
        query: gql`
          subscription($parent: UUID) { EventDelete(parent: $parent) }
        `,
        result (id) {
          deleteQuery('EventFind', 'EventDelete', this.EventFind, id)
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
