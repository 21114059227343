import { render, staticRenderFns } from "./events.vue?vue&type=template&id=f0a19896&scoped=true"
import script from "./events.vue?vue&type=script&lang=js"
export * from "./events.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f0a19896",
  null
  
)

export default component.exports